<script setup lang="ts">
const toLocalePath = useLocalePath()
</script>

<template>
  <div class="bg-gray-10">
    <div class="flex h-[5rem] flex-row items-center justify-between px-[1.25rem] lg:px-[6.25rem]">
      <NuxtLinkLocale to="/">
        <div class="w-[7.02331rem]">
          <nuxt-picture
            format="webp"
            src="/images/tja_logo.png"
            alt="TechJobAsia Logo"
            width="220"
            height="59"
            :preload="true"
          />
        </div>
      </NuxtLinkLocale>
      <ButtonIconButton mode="navigation" :to="toLocalePath('/')">
        <template #icon>
          <IconsFigmaCloseOutlined />
        </template>
      </ButtonIconButton>
    </div>
    <slot />
  </div>
</template>
